<template>
  <PageCard
    pageName="FIN Digital channels"
    :iconClass="['fa', 'icon-screen-smartphone', 'fa-lg']"
  >
    <template slot="page_content">
      <b-row class="about-row">
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Mobile Banking</div>
            <b-card-body class="p-0">
              <div>
                The Retail Mobile Banking module delivers comprehensive data and
                innovative metrics providing insight into these critical
                performance areas:
              </div>

              <div class="my-2">
                <strong>Vendor Analytics.</strong> Covering over 50 vendors
                providing mobile banking applications, the Mobile Banking module
                produces comparative vendor performance dashboards including
                market share, share accretion, win-loss counts, churn rates
                (shown below), customer utilization, customer satisfaction,
                client demographics, and individual vendor profile.
              </div>
              <div class="my-2">
                <strong>Institution Analytics.</strong> Covering over 7,000
                banks and credit unions offering mobile banking applications,
                the institution analytics generate comparative segment
                performance dashboards including mobile adoption, vendor churn
                rates, customer utilization (shown below), mobile feature
                offering, customer satisfaction and individual institution
                profiles with peer comparisons.
              </div>
              <b-card-img
                src="../../../../static/img/digital_channels_mobile_banking.png"
                class="rounded-0 my-2"
              />
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Online Banking</div>
            <b-card-body class="p-0">
              <div>
                The Retail Online Banking module delivers comprehensive data and
                innovative metrics providing insight into these critical
                performance areas:
              </div>

              <div class="my-2">
                <strong>Vendor Analytics.</strong> Covering over 60 vendors
                providing online banking applications, the Online Banking module
                produces comparative vendor performance dashboards including
                market share, customer utilization, client demographics, and
                individual vendor profiles with peer comparisons.
              </div>
              <b-card-img
                src="../../../../static/img/digital_channels_online_banking.png"
                class="rounded-0 my-2"
              />
              <div class="my-2">
                <strong>Institution Analytics.</strong> Covering over 9,000
                banks and credit unions offering online banking applications,
                the institution analytics generate comparative segment
                performance dashboards including OLB adoption and customer
                utilization.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <a
                    href="mailto:info@fi-navigator.com"
                    class="btn btn-secondary"
                    >More Info</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1"></b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutStrategy',
  components: {
    PageCard
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Digital Channels'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>

<style lang="scss" scoped></style>
